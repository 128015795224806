import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import LocalesTW from '../assets/json/locales/tw.json';
import LocalesEN from '../assets/json/locales/en.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'xchange-official';
  locales = {
    tw: LocalesTW,
    en: LocalesEN,
  };
  locale = LocalesTW;
  localeCurrent = 'tw';

  our_value = [
    {
      title: 'our_value.card_1.title',
      text: 'our_value.card_1.text',
    },
    {
      title: 'our_value.card_2.title',
      text: 'our_value.card_2.text',
    },
    {
      title: 'our_value.card_3.title',
      text: 'our_value.card_3.text',
    },
  ];
  who_we_are = [
    {
      text: 'who_we_are.card_1.text',
      object_position: 'left',
    },
    {
      text: 'who_we_are.card_2.text',
      object_position: 'inherit',
    },
    {
      text: 'who_we_are.card_3.text',
      object_position: 'inherit',
    },
    {
      text: 'who_we_are.card_4.text',
      object_position: 'left',
    },
    {
      text: 'who_we_are.card_5.text',
      object_position: 'left',
    },
  ];
  locations = [
    'tokyo',
    'taipei',
    'beijing',
    'shanghai',
    'hangzhou',
    'hongkong',
    'bangkok',
    'singapore',
    'jakarta',
  ];
  what_we_do = [
    {
      title: 'what_we_do.job_1.title',
      text: 'what_we_do.job_1.text',
    },
    {
      title: 'what_we_do.job_2.title',
      text: 'what_we_do.job_2.text',
    },
    {
      title: 'what_we_do.job_3.title',
      text: 'what_we_do.job_3.text',
    },
    {
      title: 'what_we_do.job_4.title',
      text: 'what_we_do.job_4.text',
    },
    {
      title: 'what_we_do.job_5.title',
      text: 'what_we_do.job_5.text',
    },
  ];

  constructor(private deviceService: DeviceDetectorService) {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit() {
    console.log('locale = ', this.locale);
  }

  getYear(): number {
    return new Date().getFullYear();
  }

  isMobileOnly(): boolean {
    // return isMobileOnly;
    return this.deviceService.isMobile();
  }

  isTablet(): boolean {
    // return isTablet;
    return this.deviceService.isTablet();
  }

  isPortrait(): boolean {
    return window.matchMedia('(orientation: portrait)').matches;
  }

  toggleMobileNav(): boolean {
    // this.mobileNav = !this.mobileNav;
    return true;
  }

  // hideMobileNav(): {
  //   this.mobileNav = false;
  // }

  changeLang(lang) {
    // this.$locale.change(lang);
    this.locale = this.locales[lang];
    this.localeCurrent = lang;
  }

  checkOptionActive(lang: string): object {
    return {
      'option-active': lang === this.localeCurrent,
      // 'option-active': lang === this.$locale.current(),
    };
  }

  navigateTo(url, blank = true): void {
    if (blank) {
      window.open(url);
    } else {
      const elem = document.getElementsByName(url.slice(1))[0];
      elem.scrollIntoView();
    }
  }

  getIntroText(): string {
    return this.locale['intro_text'];
    // if (this.$locale.current() === 'en' && (isMobileOnly || isTablet)) {
    //   return this.$t('intro_text_split');
    // }
    // return this.$t('intro_text');
  }
}
