<div class="container-fluid">
  <div class="row app-header" *ngIf="!isMobileOnly(); else headerMobilde" [ngClass]="{ 'app-header--tablet': isTablet(), 'app-header--portrait': isPortrait() }">
    <div class="col-md-6 flex-column app-header__col--left">
      <div class="logo">
        <img src="assets/images/index/logo.png">
      </div>
      <div class="intro-text" [ngClass]="{ 'intro-text--english': localeCurrent === 'en' }">{{ getIntroText() }}</div>
      <button type="button" class="btn btn-outline-light btn-anchor" (click)="navigateTo('#learn-more', false)">{{ locale['anchor_text'] }}</button>
    </div>
    <div class="col-md-6 flex-column app-header__col--right">
      <div class="language-selector">
        <div class="language-selector__option option-en" [ngClass]="checkOptionActive('en')" (click)="changeLang('en')">EN</div>
        <div class="language-selector__option option-ch" [ngClass]="checkOptionActive('tw')" (click)="changeLang('tw')">CH</div>
      </div>
      <div class="background-right">
        <img src="assets/images/index/hero.jpg">
      </div>
      <div class="x-overlay">
        <img src="assets/images/index/x-overlay.svg">
      </div>
    </div>
  </div>
  <ng-template #headerMobilde>
    <div class="row app-header app-header--mobile" v-else>
      <div class="col flex-column">
        <div class="logo">
          <img src="assets/images/index/logo.png">
        </div>
        <div class="intro-text" [ngClass]="{ 'intro-text--english': localeCurrent === 'en' }">{{ getIntroText() }}</div>
        <div class="background-right"></div>
        <div class="language-selector">
          <div class="language-selector__option option-en" [ngClass]="checkOptionActive('en')" (click)="changeLang('en')">EN</div>
          <div class="language-selector__option option-ch" [ngClass]="checkOptionActive('tw')" (click)="changeLang('tw')">CH</div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row our-value" [ngClass]="{ 'our-value--tablet': isTablet(), 'our-value--mobile': isMobileOnly() }">
    <div class="col">
      <a name="learn-more"></a>
      <div class="our-value__title">Our Values</div>
    </div>
    <div class="w-100"></div>
    <div class="col-12 col-md-6 col-lg-4 mb-4" *ngFor="let item of our_value">
      <div class="card" [ngClass]="{ 'card--english': localeCurrent === 'en' }">
        <div class="card-body">
          <h4 class="card-title">{{ locale[item.title.split('.')[0]][item.title.split('.')[1]][item.title.split('.')[2]] }}</h4>
          <p class="card-text">{{ locale[item.text.split('.')[0]][item.text.split('.')[1]][item.text.split('.')[2]] }}</p>
          <!-- <h4 class="card-title">{{ locale['our_value']['card_2']['title'] }}</h4> -->
          <!-- <p class="card-text">{{ locale[item.text] }}</p> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row who-we-are" [ngClass]="{ 'who-we-are--tablet': isTablet(), 'who-we-are--mobile': isMobileOnly() }">
    <div class="col-12 col-md-6 col-lg-4 mb-4 who-we-are__intro">
      <div class="intro-title">Who We Are</div>
      <div class="intro-text">{{ locale.who_we_are.intro_text }}</div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-4 who-we-are__cardcol" *ngFor="let item of who_we_are; let index = index;">
      <div class="card" [ngClass]="{ 'card--english': localeCurrent === 'en' }">
        <div class="card-body">
          <p class="card-text">{{ locale[item.text.split('.')[0]][item.text.split('.')[1]][item.text.split('.')[2]] }}</p>
          <!-- <p class="card-text">{{ locale[item.text] }}</p> -->
        </div>
        <div class="card-img-bottom">
          <img src="/assets/images/index/who-we-are-{{index + 1}}.jpg" alt="{{ locale[item.text.split('.')[0]][item.text.split('.')[1]][item.text.split('.')[2]] }}" [ngClass]="{ objectPosition: item.object_position }">
          <!-- <img v-bind:src="`/assets/images/index/who-we-are-${index + 1}.jpg`" alt="{{ locale[item.text] }}" [ngClass]="{ objectPosition: item.object_position }"> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row interview" [ngClass]="{ 'interview--tablet': isTablet(), 'interview--mobile': isMobileOnly() }">
    <div class="col video-container">
      <iframe src="https://www.youtube.com/embed/nKK5CWVgyNk?start=47" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <div class="row justify-content-center locations" *ngIf="!isMobileOnly(); else locationsMobilde">
    <div class="col-4 locations-title">Global Locations</div>
  </div>
  <ng-template #locationsMobilde>
    <div class="row locations--mobile">
      <div class="col-12">
        <div class="locations-title">Global Locations</div>
      </div>
      <div class="col-6 mb-4" *ngFor="let item of locations; let index = index;" [ngClass]="{ 'pr-2': index % 2 === 0, 'pl-2': index % 2 === 1 }">
        <div class="card locations-card">
          <img class="card-img-top" src="`/assets/images/index/locations/{{item}}.jpg`" alt="{{ locale.locations[item] }}">
          <div class="card-body">
            <h4 class="card-title">{{ locale.locations[item] }}</h4>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row what-we-do" [ngClass]="{ 'what-we-do--tablet': isTablet(), 'what-we-do--mobile': isMobileOnly() }">
    <div class="col">
      <div class="row description">
        <div class="col">
          <div class="description-title">What We Do</div>
          <div class="description-subtitle">{{ locale.what_we_do.subtitle }}</div>
        </div>
      </div>
      <div class="row jobs">
        <div class="col-12 col-lg-6 mb-3 jobs-text">
          <div class="job" *ngFor="let item of what_we_do">
            <div class="job-title">{{ locale[item.title] }}</div>
            <div class="job-text">{{ locale[item.text] }}</div>
          </div>
        </div>
        <div class="col-12 col-lg-6 jobs-photo">
          <div class="row">
            <div class="photo-wrapper col-12" [ngClass]="{ 'mb-4': !isMobileOnly(), 'mb-2': isMobileOnly() }">
              <img src="assets/images/index/what-we-do-1.jpg" :alt="{{ locale.what_we_do.job_1.text }}">
            </div>
            <div class="photo-wrapper col-8" [ngClass]="{ 'pr-0': isMobileOnly() }">
              <img src="assets/images/index/what-we-do-2.jpg" :alt="{{ locale.what_we_do.job_2.text }}">
            </div>
            <div class="photo-wrapper col-4" [ngClass]="{ 'pl-2': isMobileOnly() }">
              <img src="assets/images/index/what-we-do-3.jpg" :alt="{{ locale.what_we_do.job_3.text }}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row partners" [ngClass]="{ 'partners--tablet': isTablet(), 'partners--mobile': isMobileOnly() }">
    <div class="col">
      <div class="partners-title">Partners</div>
    </div>
    <div class="w-100"></div>
    <div class="col-6 col-md-6 col-lg-3 mb-4 logo-wrapper">
      <img class="partners-logo" src="assets/images/index/partners/asiayo.png" alt="AsiaYo!">
    </div>
    <div class="col-6 col-md-6 col-lg-3 mb-4 logo-wrapper">
      <img class="partners-logo" src="assets/images/index/partners/jkopay.svg" alt="JKOPAY街口支付">
    </div>
    <div class="col-6 col-md-6 col-lg-3 mb-4 logo-wrapper">
      <img class="partners-logo" src="assets/images/index/partners/ltsw.jpg" alt="台北市社會福利聯盟">
    </div>
    <div class="col-6 col-md-6 col-lg-3 mb-4 logo-wrapper">
      <img class="partners-logo" src="assets/images/index/partners/yourator.svg" alt="Yourator">
    </div>
  </div>

  <div class="row app-footer" [ngClass]="{ 'app-footer--tablet': isTablet(), 'app-footer--mobile': isMobileOnly() }">
    <div class="col footer-row-wrapper">
      <div class="row justify-content-center footer-title-wrapper">
        <div class="col-md-auto">
          <h4 class="footer-title">Contact Us</h4>
        </div>
      </div>
      <div class="row justify-content-center footer-text-wrapper">
        <div class="col-md-auto">
          <div class="footer-text">{{ locale.footer.text }}</div>
        </div>
      </div>
      <div class="row justify-content-center footer-contact-btn-wrapper">
        <div class="col-md-auto">
          <button type="button" class="btn btn-light btn-contact-us" (click)="navigateTo('https://m.me/XChange.tw')">{{ locale.footer.contact_button }}</button>
        </div>
      </div>
      <div class="row justify-content-center footer-logo-wrapper">
        <div class="col col-md-1 ml-auto logo">
          <a href="https://www.facebook.com/XChange.tw/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/index/social-media/facebook-icon.svg" alt="XChange Facebook">
          </a>
        </div>
        <div class="col col-md-1 logo">
          <a href="https://medium.com/xchange" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/index/social-media/medium-icon.svg" alt="XChange Medium">
          </a>
        </div>
        <div class="col col-md-1 mr-auto logo">
          <a href="https://www.linkedin.com/groups/10463057/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/index/social-media/linkedin-icon.svg" alt="XChange LinkedIn">
          </a>
        </div>
        <div class="copyright" *ngIf="!isMobileOnly()">©{{ getYear() }}XChange</div>
      </div>
      <div class="row justify-content-center copyright-wrapper" *ngIf="isMobileOnly()">
        <div class="col-6 text-center copyright">©{{ getYear()  }}XChange</div>
      </div>
    </div>
  </div>
</div>
